<template>
  <div class="business">
    <div class="breadcrumb">
      <div class="container flex flex-vc">
        <span class="homePath" @click="$router.push('/')">{{
          $t("marketMall.homeName")
        }}</span>
        <img src="@/assets/marketMall/icon_arrow.png" alt="" />
        <span>{{ $t("business.router_name") }}</span>
        <img src="@/assets/marketMall/icon_arrow.png" alt="" />
        <span>{{ $t(routerName) }}</span>
      </div>
    </div>
    <transition name="page" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  computed: {
    routerName() {
      return this.$route.meta.title;
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.business {
  .page {
    &-enter {
      opacity: 0;
      transform: translateX(-30px);
    }

    &-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    &-enter-active,
    &-leave-active {
      transition: all 0.3s ease;
    }
  }
}
</style>
